import { Pagination, TenantFilterTypeEnum } from '.';
import { CohortItemType } from './cohort.interface ';

export enum PartnerResourceTypeEnum {
  Public = 'Public',
  Private = 'Private',
  Nonprofit = 'Nonprofit',
  Other = 'Other',
}

export enum GeographyServeEnum {
  NATIONAL = 'National',
  STATE = 'State',
  ZIPCODESPECIFIC = 'Zip code-specific',
}

export enum BusinessStageEnum {
  PRE_STARTUP = 'PRE_STARTUP',
  STARTUP = 'STARTUP',
  OPERATING = 'OPERATING',
}
export interface PartnerResourcesItemType {
  id: number;
  partnerName: string;
  partnerAffiliation: string;
  type: PartnerResourceTypeEnum;
  imgUrl: string;
  outboundLink: string;
  about: string;
  geography: GeographyServeEnum;
  industries: string[];
  businessStage: BusinessStageEnum[];
  sboCommunities: string[];
  topics: string[];
  contractingNeeds: string[];
  states: string[] | null;
  zipCodes: string[] | null;
  tenantIds: number[];
  advisoryOrgId: number | null;
  fee: number;
  hasLicense: boolean;
  updatedAt: Date;
}

export interface FetchResourcesType {
  resources: PartnerResourcesItemType[];
  cohorts: CohortItemType[];
}

export type PaginatedPartnerResourcesResponseType<
  T = PartnerResourcesItemType,
> = {
  partnerResources: T[];
  count: number;
  limit: number;
  offset: number;
};
export interface PartnerResourcesSearchType extends Pagination {
  order?: string;
  filters?: string;
  parnterResourceFilter?: Object;
}

export interface PartnerResourcesChosenFilterListsType {
  topics?: string[];
  businessStage?: string[];
  sboCommunities?: string[];
  industry?: string[];
  geography?: string[];
  resourceType?: string[];
}

export type AvailablePartnerResourcesFilterOptionType =
  | 'topics'
  | 'businessStage'
  | 'sboCommunities'
  | 'industry'
  | 'geography'
  | 'contractingNeeds'
  | 'resourceType';

export type PartnerResourcesFilterOptionListType = {
  availableValues: Array<string | { value: any; name: string }>;
  anyText: string;
  description?: string;
  title: string;
  phoneText?: string;
  buttonText: string;
  isMultiple: boolean;
};

export type PartnerResourcesFilterOptions = Record<
  AvailablePartnerResourcesFilterOptionType,
  PartnerResourcesFilterOptionListType
>;

export interface FetchPartnerResourcesFilterSettings {
  id: number;
  tenantId: number;
  filterType: TenantFilterTypeEnum;
  filterSetting: PartnerResourcesFilterOptions;
  isEnabled: boolean;
  filterText: string;
}
