import React from 'react';
import {
  EmailAuthProvider,
  reauthenticateWithCredential,
} from '@firebase/auth';
import { TFunction } from 'i18next';
import { NavigateFunction } from 'react-router-dom';
import { object, ref, string } from 'yup';

import { ShowProfileSuccessPopupType } from '_types/modals.interface';
import { ProfileFieldErrorType } from '_types/profile.interface';

import UserService from '_services/userService';

import CongratulationsIcon from 'app/components/Icons/CongratulationsIcon';

import { AppDispatch } from 'store/configureStore';

import {
  actionHandleShowInfoModal,
  actionHandleShowResetPasswordModal,
} from 'store/reducers/modal';
import { actionHandleShowProfilePopup } from 'store/reducers/popups';

import { auth } from 'utils/firebase/FirebaseRemoteConfigInitialization';

export type PasswordResetObject = {
  currentPassword: string;
  newPassword: string;
  newPasswordConfirmation: string;
};

type PasswordResetErrors = Partial<
  Record<keyof PasswordResetObject, ProfileFieldErrorType>
>;

export const passwordResetValidationSchema = object({
  newPassword: string()
    .matches(/^.{8,}$/, 'auth_module.least_characters_long')
    .matches(/[A-Z]/g, 'auth_module.include_uppercase_character')
    .matches(/[#?!@$%^&*-]/g, 'auth_module.include_special_character'),
  newPasswordConfirmation: string().when('newPassword', {
    is: (value: string) => !!value,
    then: (schema) =>
      schema.oneOf([ref('newPassword')], 'common.passwords_not_matching'),
  }),
});

export const initialPasswordResetObject: PasswordResetObject = {
  currentPassword: '',
  newPassword: '',
  newPasswordConfirmation: '',
};

export const changeUserPassword = async (
  passwordResetObject: PasswordResetObject,
  setErrors: (errors: PasswordResetErrors) => void,
  setCommonError: (error: ProfileFieldErrorType) => void,
  t: TFunction,
  dispatch: AppDispatch,
) => {
  try {
    await UserService.changePassword({
      currentPassword: passwordResetObject.currentPassword,
      newPassword: passwordResetObject.newPassword,
      newPasswordConfirmation: passwordResetObject.newPasswordConfirmation,
    });
  } catch (error: any) {
    const errorsObject = error.response?.data?.errors;
    const commonError = !errorsObject
      ? error?.response?.data?.message ||
        t('common.alert_messages.error_occurred_contact_administrator')
      : null;

    setErrors(errorsObject || {});
    setCommonError(commonError);

    return;
  }

  dispatch(actionHandleShowProfilePopup(ShowProfileSuccessPopupType.password));
  dispatch(actionHandleShowResetPasswordModal(null));

  try {
    const creds = EmailAuthProvider.credential(
      auth.currentUser!.email!,
      passwordResetObject.newPassword,
    );

    await reauthenticateWithCredential(auth.currentUser!, creds);
  } catch (e) {
    // eslint-disable-next-line no-alert
    if (
      e instanceof Error &&
      e.message.includes('auth/multi-factor-auth-required')
    ) {
      alert(t('common.alert_messages.password_changed_log_in_again'));
    } else {
      alert(t('common.alert_messages.error_occurred_contact_administrator'));
    }
  }
};

export const resetUserPassword = async (
  passwordResetObject: PasswordResetObject,
  resetPasswordToken: string,
  setErrors: (errors: PasswordResetErrors) => void,
  setCommonError: (error: ProfileFieldErrorType) => void,
  t: TFunction,
  navigate: NavigateFunction,
  dispatch: AppDispatch,
  color?: string,
  // eslint-disable-next-line max-params
) => {
  try {
    await UserService.resetPassword({
      newPassword: passwordResetObject.newPassword,
      newPasswordConfirmation: passwordResetObject.newPasswordConfirmation,
      resetPasswordToken,
    });

    dispatch(
      actionHandleShowInfoModal({
        closeOnBackgroundClick: false,
        headerIcon: <CongratulationsIcon color={color} />,
        modalTitle: t('auth_module.your_password_successfully_changed'),
        content: t('auth_module.you_have_successfully_changed_your_password'),
        closeButtonText: t('auth_module.take_login_screen'),
        classNames: {
          modalContent: 'custom_content',
        },
        onModalClose: () => {
          dispatch(actionHandleShowInfoModal(null));
          navigate('/auth/login');
        },
      }),
    );
  } catch (error: any) {
    const errorsObject = error.response?.data?.errors;
    const commonError = !errorsObject
      ? error?.response?.data?.message ||
        t('common.alert_messages.error_occurred_contact_administrator')
      : null;

    setErrors(errorsObject || {});
    setCommonError(commonError);
  }
};
